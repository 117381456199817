import { SimpleConnection } from "../connection";
import { IdNamePair } from "../id-name-pair";
import { IndicatorStatus } from "../indicator-status";
import { RuleCategoryType } from "../kpi";

export class IssueAnalysisSection {
    public id: number;
    public name: string;
    public orderId: number;
    public startTime: Date;
    public endTime: Date;
    public activatedDate: Date;
    public completedDate: Date;
    public orderName: string;
    public targets: number[];
    public active: boolean;
    public defaultTarget?: number;
}

export class ReportIssue {
    public id: number;
    public ruleInstanceId: number;
    public ruleImplementationId: number;
    public startTime: Date;
    public endTime: Date;
    public ranges: Date[][];
    public problemTime: boolean;
    public problemTimeDuration: number;
    public status: IndicatorStatus;
    public subjectId: number;
    public subjectName: string;
    public subjectType: string;
    public categoryType: RuleCategoryType;
    public wellKnownServiceName: string;
}

export class IssueAnalysisTreeItem extends ReportIssue {
    public children?: ReportIssue[];
}

export class IssueAnalysisFilterLists {
    public rule: IdNamePair[];
    public subjectType: IdNamePair[];
    public serviceProvider: IdNamePair[];
    public service: IdNamePair[];
    public connections: SimpleConnection[];
    public indexType: IdNamePair[];
    public wellKnownServiceName: IdNamePair[];
}

export class IssueAnalysisDateRange {
    public startDate: Date;
    public endDate: Date;
}

export enum IssueAnalysisView{
    SubjectPie = 0,
    RulePie = 1,
    Chart = 2,
    Table = 3,
}