import { IssueAnalysisDateRange, ReportIssue } from '@/_models';
import { Store, Action } from '../ngrx-actions';
import { AddInStateIssueOverrideAction, ClearIssuesAction, ClearSectionsAction, LoadInStateFilterListsAction, LoadInStateIssueOverridesAction, LoadInStateIssuesAction, LoadInStateSectionsAction, LoadInStoreEnableMarkingFlagAction, LoadInStoreFilteredIssuesAction, LoadInStoreIssuesDateRangeAction, UpdateInStateIssuesProblemTimeAction } from './issue-analysis.actions';
import { IssueAnalysisState } from './issue-analysis.state';

@Store({} as IssueAnalysisStore)
export class IssueAnalysisStore {
    @Action(LoadInStateSectionsAction)
    public loadInStateSections(state: IssueAnalysisState, { sections }: LoadInStateSectionsAction) {
        return { ...state, sections: sections } as IssueAnalysisState;
    }
    
    @Action(LoadInStateIssuesAction)
    public loadInStateIssues(state: IssueAnalysisState, { issues }: LoadInStateIssuesAction) {
        return { ...state, issues: issues } as IssueAnalysisState;
    }
    
    @Action(LoadInStateFilterListsAction)
    public loadInStateFilterLists(state: IssueAnalysisState, { filters }: LoadInStateFilterListsAction) {
        return { ...state, filters: filters } as IssueAnalysisState;
    }

    @Action(LoadInStoreFilteredIssuesAction)
    public loadIssuesAction(state: IssueAnalysisState, { filteredIssues }: LoadInStoreFilteredIssuesAction) {
        return { ...state, filteredIssues: filteredIssues } as IssueAnalysisState;
    }
    
    @Action(LoadInStoreIssuesDateRangeAction)
    public loadIssuesDateRangeAction(state: IssueAnalysisState, { startDate, endDate }: LoadInStoreIssuesDateRangeAction) {
        return { 
            ...state,
            dateRange: {
                startDate: startDate,
                endDate: endDate
             } as IssueAnalysisDateRange
        } as IssueAnalysisState;
    }
    
    @Action(LoadInStoreEnableMarkingFlagAction)
    public enableMarkinChangedAction(state: IssueAnalysisState, { enableMarking }: LoadInStoreEnableMarkingFlagAction) {
        return { ...state, enableMarking: enableMarking } as IssueAnalysisState;
    }
    
    @Action(LoadInStateIssueOverridesAction)
    public loadInStateOverrides(state: IssueAnalysisState, { overrides }: LoadInStateIssueOverridesAction) {
        return { ...state, overrides: overrides } as IssueAnalysisState;
    }
    @Action(AddInStateIssueOverrideAction)
    public addInStateOverride(state: IssueAnalysisState, { override }: AddInStateIssueOverrideAction) {
        if (!state.overrides || !override) {
            throw new Error('Overrides state not defined');
        }

        if (override.id == null) {
            throw new Error(`Override id (${override.id}) not defined`);
        }

        const newOverrides = [...state.overrides];
        newOverrides.push(override);

        return { ...state, overrides: newOverrides } as IssueAnalysisState;
    }

    @Action(UpdateInStateIssuesProblemTimeAction)
    public updateInStateIssuesPtFlagAction(state: IssueAnalysisState, { issueIds, isProblemTime }: UpdateInStateIssuesProblemTimeAction) {
        if (!state.issues) {
            throw new Error('Issues state not defined');
        }

        if (!Array.isArray(issueIds)) {
            throw new Error(`Issue collection is not defined`);
        }

        const newIssues = [...state.issues];

        issueIds.forEach(id => {
            const index = state.issues.findIndex(y => y.id === id);

            if (index < 0) {
                throw new Error(`Issue (id: ${id}) not found in state`);
            }

            newIssues[index] = { 
                ...newIssues[index],
                problemTime: isProblemTime,
                problemTimeDuration: this.calculateProblemTimeDuration(newIssues[index], isProblemTime)
            };
        });

        return { ...state, issues: newIssues } as IssueAnalysisState;
    }
    
    @Action(ClearSectionsAction)
    public clearSectionsAction(state: IssueAnalysisState) {
        return { ...state, sections: null } as IssueAnalysisState;
    }
    
    @Action(ClearIssuesAction)
    public clearIssuesAction(state: IssueAnalysisState) {
        return { ...state, issues: null, filteredIssues: null } as IssueAnalysisState;
    }

    private calculateProblemTimeDuration(issue: ReportIssue, isProblemTime: boolean) :number {
        let duration = 0;

        issue.ranges.forEach(range => {
            duration += ((range[1] || new Date()).getTime() - range[0].getTime()) / 1000;
        })
    
        return isProblemTime ? Math.floor(duration) || 1 : 0;
    }
}
