export enum Actions {
    MaintainSystem = 'Maintain System',
    MaintainUsers = 'Maintain Users',
    MnemonicUpload = 'Mnemonic Upload',
    ViewMnemonicCatalog = 'View Mnemonic Catalog',
    OrderManagement = 'Order Management',
    IssueProblemTime = 'Issue Problem Time',
    AlertResolve = 'Alert resolve',
    AlertVerifyResolved = 'Alert verify resolved',
    RuleManagementViewer = 'Rule management viewer',
    RuleManagementEditor = 'Rule management editor',
    ProcessOrderSection = 'Process Order Section',
    UninterruptedMonitoring = 'Monitor Without Logout',
    MaintainsConnections = 'Maintains Connections',
    MaintainsPicklists = 'Maintains Picklists',
    ViewOrderManagement = 'View Order Management',
    KPIView = 'KPI View',
    KPIEdit = 'KPI Edit',
    KPIExport = 'KPI Export',
    ChangeRuleInstanceState = 'Change Rule Instance State',
    UpdateOwnSectionCurves = 'Update Own Section Curves',
    ViewMonitorPage = 'View Monitor Page',
    MaintainsAppliances = 'Maintains Appliances',
    TransferManagementView = 'Transfer Management View',
    TransferManagementChange = 'Transfer Management Change',
    MerlinManagementView = 'Merlin Management View',
    MerlinManagementChange = 'Merlin Management Change',
    MerlinManagementApprove = 'Merlin Management Approve',
    MerlinManagementAuthChange = 'Merlin Management Auth Change',
    DapTemplateManagement = 'DAP Template Management',
    DapDocumentEdit = 'DAP Document Edit',
    DapDocumentView = 'DAP Document View',
    AdminOrderSection = 'Administer Order Section',
    DapMaintainApiLicenses = 'Maintain DAP API licenses',
    DapScoreEdit = 'DAP Score Edit',
    DapSignOff = 'DAP Sign-Off',
    DapDocumentStatusSave = 'DAP Document Status Save',
    SuppressIssues = 'Suppress Issues',
    IssueAnalysisView = 'Issue Analysis View',
    IssueAnalysisEdit = 'Issue Analysis Edit'
}

export class Action {
    id: number;
    name: Actions | string;
}

