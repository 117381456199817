import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PublicPart } from 'ngx-shared';
import { environment } from 'environments/environment';

import { IssueAnalysisFilterLists, ReportIssue, IssueAnalysisSection, KpiOverride } from '@/_models';
import { formatDateInNonIsoFormat } from '@/_helpers';
@Injectable({ providedIn: 'root' })
export class IssueAnalysisService {
    constructor(private http: HttpClient) { }

    getSections(): Observable<IssueAnalysisSection[]> {
        return this.http.get<IssueAnalysisSection[]>(`${environment.portalWebApi}/IssueAnalysis/sections`)
        .pipe(map(sections => mapSectionDateTimes(sections)));
    }

    getIssues(sectionId: number, targetId: number): Observable<ReportIssue[]> {
        return this.http.get<ReportIssue[]>(`${environment.portalWebApi}/IssueAnalysis/section/${sectionId}/target/${targetId}`)
        .pipe(map(issues => mapIssueItemDateTimes(issues)));
    }

    getFilterLists(): Observable<IssueAnalysisFilterLists> {
        return this.http.get<IssueAnalysisFilterLists>(`${environment.portalWebApi}/IssueAnalysis/filters`);
    }

    updateProblemTimeFlag(issueIds: number[], isProblemTime: boolean): Observable<void> {
        return this.http.post<void>(`${environment.portalWebApi}/IssueAnalysis/issues/problemtime`, { issueIds, isProblemTime: isProblemTime });
    }

    getOverrides(sectionId: number, witsmlServerConnectionId: number): Observable<KpiOverride[]> {
        return this.http.get<KpiOverride[]>(`${environment.portalWebApi}/IssueAnalysis/overrides/${sectionId}/${witsmlServerConnectionId}`).pipe(
            map(x => x.map(mapOverrideDateTime))
        );
    }

    createOverride(override: KpiOverride): Observable<KpiOverride> {
        const overrideToCreate = {
            ...override,
            startDateTimeUtc: formatDateInNonIsoFormat(override.startDateTimeUtc),
            endDateTimeUtc: formatDateInNonIsoFormat(override.endDateTimeUtc)
        } as KpiOverride;
        return this.http.post<KpiOverride>(`${environment.portalWebApi}/IssueAnalysis/overrides/create`, overrideToCreate).pipe(
            map(mapOverrideDateTime)
        );
    }
}


/* eslint-disable */
export class FakeIssueAnalysisService implements PublicPart<IssueAnalysisService> {
    getSections(): Observable<IssueAnalysisSection[]> { throw new Error('Not implemented'); }

    getIssues(sectionId: number, targetId: number): Observable<ReportIssue[]> { throw new Error('Not implemented'); }

    getFilterLists(): Observable<IssueAnalysisFilterLists> { throw new Error('Not implemented'); }

    updateProblemTimeFlag(issueIds: number[], isProblemTime: boolean): Observable<void> { throw new Error('Not implemented'); }

    getOverrides(sectionId: number, witsmlServerConnectionId: number): Observable<KpiOverride[]> { throw new Error('Not implemented'); }

    createOverride(override: KpiOverride): Observable<KpiOverride> { throw new Error('Not implemented'); }
}
/* eslint-enable */

function mapOverrideDateTime(override: KpiOverride): KpiOverride {
    return {
        ...override,
        startDateTimeUtc: override.startDateTimeUtc ? new Date(override.startDateTimeUtc) : override.startDateTimeUtc,
        endDateTimeUtc: override.endDateTimeUtc ? new Date(override.endDateTimeUtc) : override.endDateTimeUtc,
    };
}

function mapDateTimeOffset(date: any): Date {
    return date ? new Date(date.toString().replace('+00:00','')) : null
}

function mapIssueItemDateTimes(issues: ReportIssue[]): ReportIssue[] {
    issues?.forEach(x => {
        x.startTime = mapDateTimeOffset(x.startTime);
        x.endTime = mapDateTimeOffset(x.endTime);
        x.ranges = [[x.startTime, x.endTime]]
    });
    
    return issues;
}


function mapSectionDateTimes(sections: IssueAnalysisSection[]): IssueAnalysisSection[] {
    sections?.forEach(x => {
        x.startTime = mapDateTimeOffset(x.startTime);
        x.endTime = mapDateTimeOffset(x.endTime);
        x.activatedDate = mapDateTimeOffset(x.activatedDate);
        x.completedDate = mapDateTimeOffset(x.completedDate);
    });
    
    
    return sections;
}