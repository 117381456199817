export class KPIExportDetails {
    public drqSectionId: number;
    public witsmlServerConnectionId: number;
    public kpiSectionId: number;
    public exportedBy: string;
    public isFinalized: boolean;
}

export class KPIExportStatus {
    public progress: number;
    public status: string;
    public reason: string;
}

export class KPIExportTracking {
    public sectionId: number | null;
    public targetId: number | null;
    public canceled: boolean;
}

export enum KPIExportStatuses {
    Live = 'Live',
    NotExported = 'Not Exported',
    Exported = 'Exported',
    InProgress = 'InProgress',
    Finalized = 'Finalized'
}

export enum KpiPeriod {
    LastWeek = 'Last week',
    LastMonth = 'Last month',
    LastQuarter = 'Last quarter',
    LastYear = 'Last year'
}
