import { KpiRange } from './kpi-range';
import { IdNamePair } from '../id-name-pair';
import { RuleCategoryType } from './rule-category-type';

export class SectionWithRuleInstances {
    public id: number;
    public sectionKpiRanges: KpiRange[];
    public ruleInstances: RuleInstanceSimple[];
    public rules: IdNamePair[];
}

export class RuleInstanceSimple {
    public id: number;
    public categoryType: RuleCategoryType;
    public categoryTypeName: string;
    public categoryService: string;
    public categorySubject: string;
    public ruleId: number;
    public ruleName: string;
    public subjectId: number;
    public subjectName: string;
    public logName: string;
    public wellKnownServiceName: string;
}
