import { IssueAnalysisDateRange, IssueAnalysisFilterLists, ReportIssue, IssueAnalysisSection, KpiOverride } from '@/_models';

export class IssueAnalysisState {
    public sections: IssueAnalysisSection[];
    public issues: ReportIssue[];
    public filteredIssues: ReportIssue[];
    public overrides: KpiOverride[];
    public filters: IssueAnalysisFilterLists;
    public dateRange: IssueAnalysisDateRange;
    public enableMarking: boolean;
}
