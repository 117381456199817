import { Actions } from '@/_models';
import { Routes } from './routes';

export const routeAuthorization = new Map<string, Actions[]>();
routeAuthorization.set(Routes.alerts, [Actions.AlertResolve]);
routeAuthorization.set(Routes.orderManagement, [Actions.OrderManagement, Actions.ViewOrderManagement]);
routeAuthorization.set(Routes.monitor, [Actions.ViewMonitorPage, Actions.ProcessOrderSection]);
routeAuthorization.set(Routes.kpi, [Actions.KPIView, Actions.KPIEdit, Actions.KPIExport]);
routeAuthorization.set(Routes.mnemonics, [Actions.ViewMnemonicCatalog, Actions.MnemonicUpload]);
routeAuthorization.set(Routes.rules, [Actions.RuleManagementViewer]);
routeAuthorization.set(Routes.transferManagement, [Actions.TransferManagementView, Actions.TransferManagementChange]);
routeAuthorization.set(Routes.merlinManagement, [Actions.MerlinManagementView, Actions.MerlinManagementChange]);
routeAuthorization.set(Routes.dapDocumentDetails, [Actions.DapDocumentEdit, Actions.DapDocumentView]);
routeAuthorization.set(Routes.dapDocumentOverview, [Actions.DapDocumentView, Actions.DapDocumentEdit]);
routeAuthorization.set(Routes.dapStatusOverview, [Actions.DapDocumentView, Actions.DapScoreEdit, Actions.DapSignOff]);
routeAuthorization.set(Routes.dapDocumentStatus, [Actions.DapScoreEdit, Actions.DapDocumentView]);
routeAuthorization.set(Routes.admin, [
    Actions.MaintainSystem, Actions.MaintainUsers, Actions.MaintainsPicklists, Actions.MaintainsConnections,
    Actions.MaintainsAppliances, Actions.DapTemplateManagement, Actions.DapMaintainApiLicenses,
]);
routeAuthorization.set(Routes.adminSystemConfig, [Actions.MaintainSystem]);
routeAuthorization.set(Routes.adminUsers, [Actions.MaintainUsers]);
routeAuthorization.set(Routes.adminPickLists, [Actions.MaintainsPicklists]);
routeAuthorization.set(Routes.adminConnections, [Actions.MaintainsConnections]);
routeAuthorization.set(Routes.adminAppliances, [Actions.MaintainsAppliances]);
routeAuthorization.set(Routes.dapTemplateManagement, [Actions.DapTemplateManagement]);
routeAuthorization.set(Routes.dapLicenseManagement, [Actions.DapMaintainApiLicenses]);
routeAuthorization.set(Routes.issue, [Actions.IssueAnalysisView]);