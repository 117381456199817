export const MONITOR_SECTION_PAGE_REFRESH_INTERVAL = 60000;
export const MONITOR_SECTION_DEFAULT_TREND_PERIOD = 3; //6h
export const MONITOR_SECTION_DEFAULT_TREND_PERIOD_THRESHOLD_TO_SAVE = 3; //6h, min value to be save
export const MONITOR_SECTION_DEFAULT_TREND_PERIOD_VALID_TIME = 1; //in days

export enum TaggedCurvePlotMode {
    Hidden,
    OwnAxis,
    SharedAxis
}

export class TaggedCurvePlot {
    public tag: string;
    public axes: TaggedCurvePlotMode;
    public inverted = false;
    public from0 = false;
}
  
export class MonitorSectionSettings {
    public timeZone: string;
    public showDepth: boolean;
    public depthFrom0: false;
    public additionalCurvesEnabled = false;
    public additionalCurves: TaggedCurvePlot[];
    public trendPeriod: number;
    public trendPeriodValidUntil = null;
}

export enum MonitorSelectionSource {
    Issues,
    DrqKpi,
    DapScores,
}

export class MonitorSelectionInfo {
    public source: MonitorSelectionSource;
    public selectedKey?: string;
    public selectedDimension?: string;
    public selectedSubKeys?: string[];
    public selectedTargetId?: number;
    public selectedTargetName?: string;
    public selectedTargetUrl?: string;
}
