import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, Injector } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';

import { UserDropdownModule } from './user-dropdown/user-dropdown.module';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import * as locales from 'ngx-bootstrap/locale';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import {
    NgxAsideModule,
    NgxFooterModule,
    NgxHeaderModule,
    NgxSidebarModule,
    NgxErrorModule,
    NgxAlertModule,
} from 'ngx-shared';

import { Routes, routeAuthorization, supportedAngularLocales, supportedNgxBootstrapLocales } from './configuration';
import { AppStateModule } from './state/app-state.module';
import { NgxAuthorizationGuard } from './_guard';

import { AppComponent } from './app.component';

import { InternalServerErrorComponent } from './error-pages/internal-server-error/internal-server-error.component';
import { NotFoundErrorComponent } from './error-pages/not-found-error/not-found-error.component';
import { LoadingIndicatorModule, LoadingIndicatorService, LoadingIndicatorInterceptor } from './_helpers/loading-indicator';

import { ModalDialogModule, NGX_BOOTSTRAP_LOCALE_ID } from './_helpers';
import { PostLogoutComponent } from './post-logout/post-logout.component';

import { ConfigurationService } from './_services/configuration.service';
import { AuthenticationService, MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './_services';

import {
    MsalInterceptor,
    MsalModule,
    MsalService,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MsalBroadcastService,
    MSAL_INTERCEPTOR_CONFIG,
    MsalRedirectComponent
  } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

@NgModule({
    declarations: [
        AppComponent,
        InternalServerErrorComponent,
        NotFoundErrorComponent,
        PostLogoutComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        LoggerModule.forRoot({
            // serverLoggingUrl: '/api/logs',
            disableConsoleLogging: environment.production,
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR
        }),

        NgxAsideModule,
        NgxFooterModule,
        NgxHeaderModule,
        NgxSidebarModule,
        NgxErrorModule,
        NgxAlertModule,

        BsDropdownModule.forRoot(),

        LoadingIndicatorModule,
        RouterModule.forRoot([
            { path: Routes.default, redirectTo: Routes.home, pathMatch: 'full' },
        
            { path: Routes.postLogout, component: PostLogoutComponent },
            { path: Routes.serverError, component: InternalServerErrorComponent },
            {
                path: Routes.home,
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.home) }
            }, {
                path: Routes.alerts,
                loadChildren: () => import('./alert/alert.module').then(m => m.AlertModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.alerts) }
            }, {
                path: Routes.orderManagement,
                loadChildren: () => import('./order-management/order-management.module').then(m => m.OrderManagementModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.orderManagement) }
            }, {
                path: Routes.monitor,
                loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.monitor) }
            }, {
                path: Routes.issue,
                loadChildren: () => import('./issue-analysis/issue-analysis.module').then(m => m.IssueAnalysisModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.issue) }
            }, {
                path: Routes.kpi,
                loadChildren: () => import('./kpi/kpi.module').then(m => m.KpiModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.kpi) }
            }, {
                path: Routes.mnemonics,
                loadChildren: () => import('./mnemonics/mnemonics.module').then(m => m.MnemonicsModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.mnemonics) }
            }, {
                path: Routes.rules,
                loadChildren: () => import('./rule-management/rule-management.module').then(m => m.RuleManagementModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.rules) }
            }, {
                path: Routes.transferManagement,
                loadChildren: () => import('./transfer-management/transfer-management.module').then(m => m.TransferManagementModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.transferManagement) }
            }, {
                path: Routes.merlinManagement,
                loadChildren: () => import('./merlin-management/merlin-management.module').then(m => m.MerlinManagementModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.merlinManagement) }
            }, {
                path: Routes.dapDocumentDetails,
                loadChildren: () =>
                    import('./dap/document-details/dap-document-details.module').then(m => m.DapDocumentDetailsModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.dapDocumentDetails) }
            }, {
                path: Routes.dapTemplateManagement,
                loadChildren: () =>
                    import('./dap/template-management/dap-template-management.module').then(m => m.DapTemplateManagementModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.dapTemplateManagement) }
            }, {
                path: Routes.dapDocumentOverview,
                loadChildren: () =>
                    import('./dap/document-overview/dap-document-overview.module').then(m => m.DapDocumentOverviewModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.dapDocumentOverview) }
            }, {
                path: Routes.dapStatusOverview,
                loadChildren: () =>
                    import('./dap/status-overview/dap-status-overview.module').then(m => m.DapStatusOverviewModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.dapStatusOverview) }
            }, {
                path: Routes.dapDocumentStatus,
                loadChildren: () =>
                    import('./dap/document-status/dap-document-status.module').then(m => m.DapDocumentStatusModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.dapDocumentStatus) }
            }, {
                path: Routes.dapLicenseManagement,
                loadChildren: () =>
                    import('./dap/license-management/license-management.module').then(m => m.DapLicensesModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.dapLicenseManagement) }
            }, {
                path: Routes.adminSystemConfig,
                loadChildren: () =>
                    import('./admin/system-configuration/system-configuration.module').then(m => m.SystemConfigurationModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.adminSystemConfig) }
            }, {
                path: Routes.adminUsers,
                loadChildren: () => import('./admin/users/users.module').then(m => m.UsersModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.adminUsers) }
            }, {
                path: Routes.adminPickLists,
                loadChildren: () => import('./admin/pick-lists/pick-lists.module').then(m => m.PickListsModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.adminPickLists) }
            }, {
                path: Routes.adminConnections,
                loadChildren: () => import('./admin/connections/connections.module').then(m => m.ConnectionsModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.adminConnections) }
            }, {
                path: Routes.adminAppliances,
                loadChildren: () => import('./admin/appliance/appliance.module').then(m => m.ApplianceModule),
                canActivate: [NgxAuthorizationGuard],
                data: { actions: routeAuthorization.get(Routes.adminAppliances) }
            },
            { path: '**', component: NotFoundErrorComponent }
        ],
        {
            // don't perform initial navigation in iframe or popups to avoid redirecting the parent window
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
            
            // debugging purposes only                
            // enableTracing: true
        }),
        UserDropdownModule,
        AppStateModule,
        ModalDialogModule,
        AlertModule.forRoot(),
        HttpClientModule,
        MsalModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigurationService) => () => configService.init(),
            deps: [ConfigurationService],
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [ConfigurationService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
            deps: [ConfigurationService]
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ConfigurationService]
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
            useFactory: (authService: AuthenticationService) => () => {}, 
            deps: [AuthenticationService, Injector],
            multi: true
        },
        LoadingIndicatorService,
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: (service: LoadingIndicatorService) => new LoadingIndicatorInterceptor(service),
            multi: true,
            deps: [LoadingIndicatorService]
        },
        { provide: LOCALE_ID, useFactory: () => getUserLocale() },
        { provide: NGX_BOOTSTRAP_LOCALE_ID, useFactory: getNgxBootstrapLocale, deps: [LOCALE_ID] },
        BsLocaleService,
        MsalService,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}

function getUserLocale() {
    const angularDefaultLocale = 'en-US';
    const userLocale = window.navigator.language || angularDefaultLocale;

    if (userLocale === angularDefaultLocale) {
        return angularDefaultLocale; // angular's default
    }

    const [parent, child] = userLocale.toLowerCase().split('-');
    const locale = parent === child ? parent : userLocale;

    if (!supportedAngularLocales.find(x => x === locale)) {
        return angularDefaultLocale; // unsupported locale defaults to angular's default
    }

    return userLocale;
}

export function getNgxBootstrapLocale(locale: string): string {
    const defaultLocale = 'en';

    if (locale === defaultLocale) {
        return defaultLocale;
    }

    const [parent, child] = locale.toLowerCase().split('-');
    const ngxLocale = parent === child ? parent : locale;

    if (!supportedNgxBootstrapLocales.find(x => x === ngxLocale)) {
        return defaultLocale;
    }

    const ngxLocaleName = `${parent}${parent !== child && child ? child.charAt(0).toUpperCase() + child.substring(1) : ''}Locale`;

    return locales[ngxLocaleName].abbr;
}
