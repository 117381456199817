export enum SectionStatses {
    Active = 'Active',
    Completed = 'Completed'
}

export enum SectionPeriod {
    LastWeek = 'Last week',
    LastMonth = 'Last month',
    LastQuarter = 'Last quarter',
    LastYear = 'Last year'
}
