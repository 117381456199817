import { Action } from '@ngrx/store';

import { IssueAnalysisFilterLists, ReportIssue, IssueAnalysisSection, KpiOverride } from '@/_models';

export class LoadInStateSectionsAction implements Action {
    public type = '[IssueAnalysis] Load in state sections action';
    constructor(public readonly sections?: IssueAnalysisSection[]) { }
}
export class LoadInStateIssuesAction implements Action {
    public type = '[IssueAnalysis] Load in state issues action';
    constructor(public readonly issues?: ReportIssue[]) { }
}
export class LoadInStateFilterListsAction implements Action {
    public type = '[IssueAnalysis] Load in state filter lists action';
    constructor(public readonly filters?: IssueAnalysisFilterLists) { }
}
export class LoadInStateIssueOverridesAction implements Action {
    public type = '[IssueAnalysis] Load in state issue overrides action';
    constructor(public readonly overrides?: KpiOverride[]) { }
}
export class ClearSectionsAction implements Action {
    public type = '[IssueAnalysis] Clear in state sections action';
}
export class ClearIssuesAction implements Action {
    public type = '[IssueAnalysis] Clear in state issues action';
}

//#region issue filtration
export class LoadInStoreFilteredIssuesAction implements Action {
    public type = '[IssueAnalysis] Load in state filtered issues action';
    constructor(public readonly filteredIssues?: ReportIssue[]) { }
}

export class LoadInStoreIssuesDateRangeAction implements Action {
    public type = '[IssueAnalysis] Load in state issues date range action';
    constructor(public readonly startDate?: Date, public readonly endDate?: Date) { }
}

export class LoadInStoreEnableMarkingFlagAction implements Action{
    public type = '[IssueAnalysis] Load in  state enable marking flag action';
    constructor(public readonly enableMarking: boolean) { }
}
//#endregion issue filtration

//#region sections
export class FetchSectionsAction implements Action {
    public type = '[IssueAnalysis] Fetch sections action';
}
export class FetchOkSectionsAction implements Action {
    public type = '[IssueAnalysis] Fetch OK sections action';
}
export class FetchFailedSectionsAction implements Action {
    public type = '[IssueAnalysis] Fetch Failed sections action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion sections

//#region issues
export class FetchIssuesAction implements Action {
    public type = '[IssueAnalysis] Fetch issues action';
    constructor(public sectionId: number,  public targetId: number) { }
}
export class FetchOkIssuesAction implements Action {
    public type = '[IssueAnalysis] Fetch OK issues action';
}
export class FetchFailedIssuesAction implements Action {
    public type = '[IssueAnalysis] Fetch Failed issues action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion issues


//#region filters
export class FetchFilterListsAction implements Action {
    public type = '[IssueAnalysis] Fetch filter lists action';
}
export class FetchOkFilterListsAction implements Action {
    public type = '[IssueAnalysis] Fetch OK filter lists action';
}
export class FetchFailedFilterListsAction implements Action {
    public type = '[IssueAnalysis] Fetch Failed filter lists action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion filters


//#region update problem time
export class UpdateInStateIssuesProblemTimeAction implements Action {
    public type = '[IssueAnalysis] Update in state issues ProblemTime action';
    constructor(public readonly issueIds: number[], public readonly isProblemTime: boolean) { }
}
export class UpdateIssuesProblemTimeAction implements Action {
    public type = '[IssueAnalysis] Update issues ProblemTime action';
    constructor(public readonly issueIds: number[], public readonly isProblemTime: boolean) { }
}
export class UpdateOKIssuesProblemTimeAction implements Action {
    public type = '[IssueAnalysis] Update OK issues ProblemTime action';
}
export class UpdateFailedIssuesProblemTimeAction implements Action {
    public type = '[IssueAnalysis] Update Failed issues ProblemTime action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion update problem time

//#region overrides fetch
export class FetchIssueOverridesAction implements Action {
    public type = '[IssueAnalysis] Fetch issue overrides action';
    constructor(public readonly sectionId: number, public readonly targetId: number) { }
}
export class FetchOkIssueOverridesAction implements Action {
    public type = '[IssueAnalysis] Fetch OK issue overrides action';
}
export class FetchFailedIssueOverridesAction implements Action {
    public type = '[IssueAnalysis] Fetch Failed issue overrides action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion overrides fetch
//#region overrides create
export class AddInStateIssueOverrideAction implements Action {
    public type = '[IssueAnalysis] Add in state issue override action';
    constructor(public readonly override: KpiOverride) { }
}
export class CreateIssueOverrideAction implements Action {
    public type = '[IssueAnalysis] Create issue override action';
    constructor(public readonly override: KpiOverride) { }
}
export class CreateOkIssueOverrideAction implements Action {
    public type = '[IssueAnalysis] Create OK issue override action';
}
export class CreateFailedIssueOverrideAction implements Action {
    public type = '[IssueAnalysis] Create Failed issue override action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion overrides create