import { Actions } from '@/_models';
import { Routes } from './routes';
import { routeAuthorization } from './route-authorization';

export class Attributes {
    disabled: boolean;
}
export class NavItem {
    name: string;
    url?: string;
    icon?: string;
    children?: NavItem[];
    attributes?: Attributes;
    restrictToActions?: Actions[];
    disabledByFeatureFlag?: string;
}

export const navigationMenuItems: NavItem[] = [
    {
        name: 'Home',
        url: Routes.home,
        icon: 'fa fa-home'
    },
    {
        name: 'Alerts',
        url: Routes.alerts,
        icon: 'fa fa-envelope',
        restrictToActions: routeAuthorization.get(Routes.alerts)
    },
    {
        name: 'Orders',
        url: Routes.orderManagement,
        icon: 'fa-solid fa-clipboard-list-check',
        restrictToActions: routeAuthorization.get(Routes.orderManagement)
    },
    {
        name: 'Monitoring',
        url: Routes.monitor,
        icon: 'fa fa-desktop',
        restrictToActions: routeAuthorization.get(Routes.monitor)
    },
    {
        name: 'KPIs',
        url: Routes.kpi,
        icon: 'fa fa-tachometer-alt',
        restrictToActions: routeAuthorization.get(Routes.kpi)
    },
    {
        name: 'Issues',
        url: Routes.issue,
        icon: 'fa-solid fa-magnifying-glass-chart',
        restrictToActions: routeAuthorization.get(Routes.issue)
    },
    {
        name: 'Mnemonics',
        icon: 'fa fa-globe',
        url: Routes.mnemonics,
        restrictToActions: routeAuthorization.get(Routes.mnemonics)
    },
    {
        name: 'Rules',
        icon: 'fa fa-sitemap',
        url: Routes.rules,
        restrictToActions: routeAuthorization.get(Routes.rules)
    },
    {
        name: 'Transfers',
        icon: 'fa fa-exchange',
        url: Routes.transferManagement,
        restrictToActions: routeAuthorization.get(Routes.transferManagement)
    },
    {
        name: 'Merlin Status',
        icon: 'fa fa-magic',
        url: Routes.merlinManagement,
        restrictToActions: routeAuthorization.get(Routes.merlinManagement),
        disabledByFeatureFlag: 'isMerlinDisabled'
    },
    {
        name: 'DAP Documents',
        icon: 'fas fa-landmark',
        url: Routes.dapDocumentOverview,
        restrictToActions: routeAuthorization.get(Routes.dapDocumentOverview),
        disabledByFeatureFlag: 'isDapDisabled'
    },
    {
        name: 'DAP Status',
        icon: 'fas fa-chart-pie',
        url: Routes.dapStatusOverview,
        restrictToActions: routeAuthorization.get(Routes.dapStatusOverview),
        disabledByFeatureFlag: 'isDapDisabled'
    },
    {
        name: 'Admin',
        icon: 'fa fa-user-tie',
        children: [
            {
                name: 'System',
                url: Routes.adminSystemConfig,
                icon: 'fa fa-cogs',
                restrictToActions: routeAuthorization.get(Routes.adminSystemConfig)
            },
            {
                name: 'Users',
                url: Routes.adminUsers,
                icon: 'fa fa-users',
                restrictToActions: routeAuthorization.get(Routes.adminUsers)
            },
            {
                name: 'Picklists',
                icon: 'fa fa-list',
                url: Routes.adminPickLists,
                restrictToActions: routeAuthorization.get(Routes.adminPickLists)
            },
            {
                name: 'Targets',
                icon: 'fa fa-link',
                url: Routes.adminConnections,
                restrictToActions: routeAuthorization.get(Routes.adminConnections)
            },
            {
                name: 'Appliances',
                icon: 'fa fa-plug',
                url: Routes.adminAppliances,
                restrictToActions: routeAuthorization.get(Routes.adminAppliances)
            },
            {
                name: 'DAP Templates',
                url: Routes.dapTemplateManagement,
                icon: 'fa fa-file',
                restrictToActions: routeAuthorization.get(Routes.dapTemplateManagement),
                disabledByFeatureFlag: 'isDapDisabled'
            },
            {
                name: 'DAP Licenses',
                url: Routes.dapLicenseManagement,
                icon: 'fa fa-key',
                restrictToActions: routeAuthorization.get(Routes.dapLicenseManagement),
                disabledByFeatureFlag: 'isDapDisabled'
            }
        ],
        restrictToActions: routeAuthorization.get(Routes.admin)
    },
];
