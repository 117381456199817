import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { NGXLogger } from 'ngx-logger';
import * as actions from './issue-analysis.actions';
import { IssueAnalysisService } from '@/_services';

@Injectable()
export class IssueAnalysisEffects {
    
    public onServerFetchSections$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchSectionsAction),
        switchMap(() => this.issueAnalysisService.getSections().pipe(
            switchMap(y => [new actions.LoadInStateSectionsAction(y), new actions.FetchOkSectionsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Sections', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedSectionsAction(error));
            })
        ))
    ));

    public onServerFetchIssues$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchIssuesAction),
        switchMap((x) => this.issueAnalysisService.getIssues(x.sectionId, x.targetId).pipe(
            switchMap(x => [new actions.LoadInStateIssuesAction(x), new actions.FetchOkIssuesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Issues for analysis', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedIssuesAction(error));
            })
        ))
    ));

    public onServerFetchFilterLists$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchFilterListsAction),
        switchMap(() => this.issueAnalysisService.getFilterLists().pipe(
            switchMap(y => [new actions.LoadInStateFilterListsAction(y), new actions.FetchOkFilterListsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Filter Lists', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedFilterListsAction(error));
            })
        ))
    ));

    public onServerIssuesUpdateProblemTime$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.UpdateIssuesProblemTimeAction),
        switchMap(x => this.issueAnalysisService.updateProblemTimeFlag(x.issueIds, x.isProblemTime).pipe(
            switchMap(() => [
                new actions.UpdateInStateIssuesProblemTimeAction(x.issueIds, x.isProblemTime),
                new actions.UpdateOKIssuesProblemTimeAction()
            ]),
            catchError(error => {
                this.logger.error('Could not update issues ProblemTime flag', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.UpdateFailedIssuesProblemTimeAction(error));
            })
        ))
    ));

    public onServerFetchOverrides$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.FetchIssueOverridesAction),
        switchMap(x => this.issueAnalysisService.getOverrides(x.sectionId, x.targetId).pipe(
            switchMap(y => [new actions.LoadInStateIssueOverridesAction(y), new actions.FetchOkIssueOverridesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Issue Overrides', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.FetchFailedIssueOverridesAction(error));
            })
        ))
    ));

    public onServerCreateOverride$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.CreateIssueOverrideAction),
        switchMap(x => this.issueAnalysisService.createOverride(x.override).pipe(
            switchMap(y => [new actions.AddInStateIssueOverrideAction(y), new actions.CreateOkIssueOverrideAction()]),
            catchError(error => {
                this.logger.error('Could not Create Issue Override', error);
                this.alertService.error(getMessageFromError(error));
                return of(new actions.CreateFailedIssueOverrideAction(error));
            })
        ))
    ));

    public onClearSections$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearSectionsAction),
        map(() => new actions.LoadInStateSectionsAction())
    ));
    
    public onClearIssues$ = createEffect(() => this.actions$.pipe(
        ofAction(actions.ClearIssuesAction),
        map(() => new actions.LoadInStateIssuesAction())
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly issueAnalysisService: IssueAnalysisService,
    ) { }
}
