import { CurveIndexType, WitsmlSubjectTypes } from "@/_models";
import { ConfigurationService } from "@/_services/configuration.service";

export class DeepLinkingNavigator {
    public static getMonitoredSubjectUrl(configService: ConfigurationService, subjectType: string,
        targetUrl: string, wellUid: string, wellboreUid: string, uid: string, indexType: string,
        startDate: Date = null, endDate: Date = null, startDepth: number = null, endDepth: number = null,
        mnemonics: string[] = null): string {
        let url: string = null;
        switch (subjectType) {
            case WitsmlSubjectTypes.Target:
                url = DeepLinkingNavigator.getWellsUrl(configService, targetUrl);
                break;
            case WitsmlSubjectTypes.Well:
                url = DeepLinkingNavigator.getWellboresUrl(configService, targetUrl, wellUid);
                break;
            case WitsmlSubjectTypes.Wellbore:
                url = DeepLinkingNavigator.getLogTypesUrl(configService, targetUrl, wellUid, wellboreUid);
                break;
            case WitsmlSubjectTypes.Trajectory:
                url = DeepLinkingNavigator.getTrajectoryUrl(configService, targetUrl, wellUid, wellboreUid, uid);
                break;
            case WitsmlSubjectTypes.Log:
                url = DeepLinkingNavigator.getLogUrl(configService, targetUrl, wellUid, wellboreUid, indexType, uid);
                break;
            case WitsmlSubjectTypes.Curve:
                url = DeepLinkingNavigator.getCurveValuesUrl(configService, targetUrl, wellUid, wellboreUid,
                    indexType, uid, startDate, endDate, startDepth, endDepth, mnemonics);
                break;
            default:
                url = null;
                break;
        }

        return url;
    }

    public static getLogTypesUrl(configService: ConfigurationService, serverUrl: string, wellUid: string, wellboreUid: string): string {
        if (configService.getSettings('deepLinkingTargetType') == 'WitsmlExplorer') {
            let url = this.getWellboresUrl(configService, serverUrl, wellUid);
            if (wellboreUid != null && wellboreUid !== '') {
                url += `/${encodeURIComponent(wellboreUid)}/objectgroups/Log/logtypes`;
            }
            return url;
        }

        return null;
    }

    private static getWellsUrl(configService: ConfigurationService, serverUrl: string): string {
        if (configService.getSettings('deepLinkingTargetType') == 'WitsmlExplorer') {
            return `${configService.getSettings('deepLinkingTarget')}servers/${encodeURIComponent(serverUrl)}/wells`;
        }

        return null;
    }

    private static getWellboresUrl(configService: ConfigurationService, serverUrl: string, wellUid: string): string {
        if (configService.getSettings('deepLinkingTargetType') == 'WitsmlExplorer') {
            let url = `${configService.getSettings('deepLinkingTarget')}servers/${encodeURIComponent(serverUrl)}/wells`;
            if (wellUid != null && wellUid !== '') {
                url += `/${encodeURIComponent(wellUid)}/wellbores`;
            }
            return url;
        }

        return null;
    }

    private static getTrajectoryUrl(configService: ConfigurationService, serverUrl: string, wellUid: string, wellboreUid: string, uid: string): string {
        if (configService.getSettings('deepLinkingTargetType') == 'WitsmlExplorer') {
            let url = this.getWellboresUrl(configService, serverUrl, wellUid);
            if (wellboreUid != null && wellboreUid !== '') {
                url += `/${encodeURIComponent(wellboreUid)}/objectgroups/Trajectory/objects`;
                if (uid != null && uid !== '') {
                    url += `/${uid}`
                }
            }
            return url;
        }

        return null;
    }

    private static getLogUrl(configService: ConfigurationService, serverUrl: string, wellUid: string, wellboreUid: string, indexType: string, uid: string): string {
        if (configService.getSettings('deepLinkingTargetType') == 'WitsmlExplorer') {
            let url = this.getLogTypesUrl(configService, serverUrl, wellUid, wellboreUid)
            if (indexType === CurveIndexType.DateTime || indexType === CurveIndexType.MeasuredDepth) {
                const logType = indexType === CurveIndexType.DateTime ? 'time' : 'depth';
                url += `/${logType}/objects`
                if (uid != null && uid !== '') {
                    url += `/${uid}`
                }
            }
            return url;
        }

        return null;
    }

    private static getCurveValuesUrl(configService: ConfigurationService, serverUrl: string, wellUid: string, wellboreUid: string,
        indexType: string, logUid: string, startDate: Date = null, endDate: Date = null, startDepth: number = null, endDepth: number = null,
        mnemonics: string[] = null): string {
        if (configService.getSettings('deepLinkingTargetType') == 'WitsmlExplorer') {
            let url = this.getLogTypesUrl(configService, serverUrl, wellUid, wellboreUid)
            if (indexType === CurveIndexType.DateTime || indexType === CurveIndexType.MeasuredDepth) {
                const logType = indexType === CurveIndexType.DateTime ? 'time' : 'depth';
                url += `/${logType}/objects`
                if (logUid != null && logUid !== '') {
                    url += `/${logUid}/curveValues`;
                    let filter = '';
                    if (indexType === CurveIndexType.DateTime) {
                        if (startDate != null) {
                            filter = `startIndex=${encodeURIComponent(startDate.toISOString())}`;
                        }
                        if (endDate != null) {
                            if (filter !== '') {
                                filter += '&';
                            }
                            filter += `endIndex=${encodeURIComponent(endDate.toISOString())}`;
                        }
                    } else {
                        if (startDepth != null) {
                            filter = `startIndex=${encodeURIComponent(startDepth.toString())}`;
                        }
                        if (endDepth != null) {
                            if (filter !== '') {
                                filter += '&';
                            }
                            filter += `endIndex=${encodeURIComponent(endDepth.toString())}`;
                        }
                    }
                    if (mnemonics != null && mnemonics.length > 0) {
                        if (filter !== '') {
                            filter += '&';
                        }
                        const mappedMnemonics = mnemonics.map(x => `"${x}"`);
                        const mnemonicsString = `[${mappedMnemonics.toString()}]`;
                        filter += `mnemonics=${encodeURIComponent(mnemonicsString)}`;
                    }

                    if (filter !== '') {
                        url += `?${filter}`;
                    }
                }
            }
            return url;
        }

        return null;
    }
}